@use "src/_btn.scss";
@use "src/_theme.scss";
@use "src/_controls.scss";
@use "src/_inv.scss";
@use "src/_store.scss";
@use "src/_utils.scss";
@use "src/_bf.scss";

$red: #FF4A4A;
$rorange: #FF6A4D;
$orange: #FF9052;
$yorange: #FFB655;
$yellow: #FFD757;
body { 
  @include theme.textFont;
  background: black;
}
strong, button, input {
  @include theme.altFont;
}

@include btn.Btn;
@include controls.Controls;
@include inv.Inv;
@include store.Store;

button.mmbtn {
  position: fixed;
  top: 16px;
  right: 16px;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
}

$h: 64px;
.header {
  position: fixed;
  bottom: 0;
  right: 0;
  height: $h;

}
.home {
  &__img {
    img {
      height: $h;

    }
    display: inline-block;
    vertical-align: middle;

  }
  &__text {
    color: white;
    display: inline-block;
    height: $h;
    line-height: $h;
    vertical-align: middle;
    font-size: $h/4;
  }

}
// if the $h is height
// 1/4 $h is blackspace in image
$m: $h/4;
$minw: $h*2;

$g: 32px;

$L: $minw + $g;

.page {
  color: white;
  display: none;
  position: fixed;
  top: $h;
  left: $L;
  bottom: 0; 
  border-left: 1px solid white;
  padding-left: $g; 
  

}
#page-wallet.page--wallet {
  display: block;
}
#page-resources.page--resources  {
  display: block;
}

#page-forge.page--forge  {
  display: block;
}

#page-stake.page--stake  {
  display: block;
}

#page-ritual.page--ritual  {
  display: block;
}
#page-faq.page--faq, #page-ritual.page--ritual  {
  display: block;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $yorange;
  }
}


$rh: 2*$h;
$rih: 4*$h/3;
$nih: 2*$h - $rih;
.resource {
  cursor: pointer;
  display: inline-block;
  margin: $g/2;
  width: $rh;
  height: $rh;
  color: white;
  border: 2px solid white;
  border-radius:6px;
  box-sizing: border-box;
  background: black;
  &__img {
    text-align: center;
    img {
      height: $rih;
      margin: 0 auto;
    }
  }
  &__name {
    text-align: center;
    width: 100%;
    line-height: $nih;
    font-size: $nih/3;
  }
  filter: invert(0);
  transform: scale(1);
  transition: filter 0.2s ease-in-out;
  &:hover{
    filter: invert(1);
    transform: scale(1.05);

  }
}

.staking-pool {
  border: 2px solid white;
  border-radius: 6px;
  padding: 16px;
  display: inline-block;
  margin: 8px;
}
.staking {
  &__name {
    font-size: 26px;
    margin-bottom: 8px;
  }
}

.q {
  font-size: 24px;
}

.faq {
  margin-bottom: 128px;
  p {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: $g;
  }
  p.q {
    margin-top: $g/2;
    margin-bottom: $g/2;
    border-top: 1px dashed white;
    padding-top: $g/2;
  }
  strong {
    color: $rorange;
  }
}





.blob {
  p {
    margin: 0;
  }
}
.action {
  margin-top: $g;
  border-top: 1px solid white;
}
button + button {
  margin-left: $g/2;
}
input {
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  text-align: center;
}

.msg {
  display: none;
}

.ready.msg--ready,
.resting.msg--resting,
.mining.msg--mining,
.tired.msg--tired {
  display: block;
}




.rh {
 display: none;
}

.isApproved {
  &--false {

  }
  &--true {
    display: none;
  }
}

.pending {
  position: fixed;
  top: -128px;
  left: 0;
  right: 0;
  height: 128px;
  background: white;
  color: black;
  text-align: center;
  font-size: 32px;
  line-height: 128px;
  transition: top 0.4s ease-in-out;
}
.pending--true {
  display: block;
  top: 0;
}

.inline {
  display: inline-block;
  vertical-align: middle;
}
.inline + .inline {
  margin-left: 8px;
}
.checker p {
  margin: 0;
}

.checker {
  margin-bottom: 16px;
}

.modal {
  strong {
    color: $rorange;
  }
}

.modal--forge {
  strong {
    color: white;
  }
}

.shrine-pic {
  img {
    max-height: 50vh;
  }
   
}

.shrine--0 {
  display: none;
}



.shrine {
  margin-bottom: 64px;
}

.ritual-forge {
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $yorange;
  } 

  table {
     margin-bottom: 64px;
  }
}

.staking__asset {
  div {
    margin-bottom: 8px;
  }
  button {
    margin-left: 8px;
  }
  input {
    line-height: 24px;
    font-size: 24px;
    width: 128px;
  }

}

.claim-cs {
  margin-bottom: 8px;
}

.page--stake {
  .scroll-help {
    height: 64px;

  }
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $yorange;
  }
}

.dialog {
  font-family: "Courier Prime", serif;
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
 
  margin: 0;

  $dW: 60vw;
  &--false {
    display: none;
  }
  &__text {
    color: white;
    font-size: 19px;
   
    
    display: inline-block;
    &--full {
      max-width: 100%;
    }
    position: absolute;
    top: 0;
    left: 20vw;
    right: 20vw;

    padding: 24px;
    background: rgba(0,0,0,0);
    p {
      margin: 12px 0;
    }

    
  }
  &__option {

    cursor: pointer;
    display: block;
    width: 100%;
    margin: 0;
    margin-top: 8px;
    color: white;
    &.dialog__option--DLkey.dialog__option--3 {
      display: none;
      &.ifThree--3 {
        display: block;
      }
    }
  }
  &__option + &__option {
    margin-top: 8px;
  }
  &__speaker {
    position: relative;
    top: 0; left: 0; right: 0; bottom: 0;
    text-align: center;
    overflow: hidden;
 height: 100vh;
   width: 100%;
  
    &--full {
   

      img {
        width: auto;
        height: 100%;
        position: relative;
      }
    }
  }
  &__active {
    position: relative;
   top: 0; left: 0; right: 0; bottom: 0;
   height: 100vh;
   width: 100%;
  }
  &__options {
    background: black;
    position: absolute;
    width: $dW;
    left: 50vw - $dW/2;
    bottom: 48px;
    box-sizing: border-box;
    padding: 16px;
    &--false {
      display: none;
    }

    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 2px;
input {
      width: 100%;
      box-sizing: border-box;
      font-size: 32px;
      text-align: left;
    }
  }
  &__nav {
    bottom: 0;
    line-height: 48px;
    font-size: 19px;
    color: white;
    width: $dW;
    left: 50vw - $dW/2;
    height: 48px;
    position: fixed;
    font-family: "Courier Prime", serif;
  }

  .nav-btn {
    position: absolute;
    cursor: pointer;
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }
  
}



.briefcase {
  border-radius: 8px;
  overflow: hidden;
  $m: 64px;

  &__title {
    color: white;
    font-size: 32px;
    text-align: center;
    width: 100%;
  }
  &__subtitle {
    font-size: 24px;
    margin-bottom: 16px;
  }
  &--false {

    display: none;
  }

  &--true {
  
    display: block;
  }

  position: fixed;

  
  padding: 24px;
  top: $m;
  left: $m;
  right: $m;
  bottom: $m;

  background: rgba(0,0,0,1);
  border: 1px solid rgba(255,255,255,0.6);
  box-sizing: border-box;

  
  transition: transform 0.2s ease-in-out;

  &__items {
    text-align: center;
    padding: 16px;
    border: 1px solid white; 
    border-radius: 4px;
    height: calc(100vh - 2 * $m - 72px - 60px);
  }

  &__item {
    display: inline-block;
    
    $w: 96px;
    width: $w;
    height: $w;
    margin: 8px;

    img {
      height: $w;
      width: $w;
    }
  }

}

.inv {
  width: 50%;
  position: absolute;
  color: white;
  top: 72px;
  &--relics {
    left: 50%;
  }
}
.familiars {
  position: absolute;
  bottom: 36px; 
  right: 36px;
  .lantern-img {
    $w: 128px;
    width: $w;
    img {
      height: $w;
      width: $w;
    }
  }
  .familiar-count {
    color: white;
  }
}

.home {
  cursor:pointer;
}

.progress-bar {
  $h: 8px;
  height: $h;
  
  border: 1px solid rgba(255,255,255,0.4);
  border-radius: $h;
  overflow: hidden;
  margin-top: 16px;
  &__inside {
    position: relative;
    border-radius: $h;
    background: white;
    height: $h;
  } 
}



.menu-image {
  width: 100%;
  img {
    width: 100%;
  }
}
$menuGap: 24px;
.menu-container {
  
  width: 416px;
border: 1px solid rgba(255,255,255,0.1);
border-radius: 2px;
  background: rgba(0,0,0,1);
  position: fixed;
  top: $menuGap;
  left: $menuGap;
  text-align: center;
  

&--false {
    display: none;
  }
}

 .menu {
  position: relative;

  padding: $menuGap;

   text-align: center;



&__title {
  color: white;
  font-size: 32px;
  font-weight: 700;
}
  &__link {
    
       
    display: block;
    width: 100%;
    font-size: 32px;
    color: white;
    cursor: pointer;
  
    box-sizing: border-box;
    margin: 24px 0;
    &:hover {
      border-bottom: 2px solid white;
    }

  
    

  }  

  &__version {
    font-size: 16px;
      width: 100%;
      text-align: center;
      color: rgba(255,255,255,0.7);
      letter-spacing: 2px;

  }  


  }


  .modal {
    $w: 640px;
    position: fixed;
    background: black;
    color: white;
    border: 2px solid white;
    padding: 0 16px 16px;
    width: $w;
    border-radius: 10px;
    left: calc(50vw - $w/2);
    $h: 416px;
    height: $h;

    margin: 0;
    top: calc(50vh - $h/2);


    p {
      font-size: 19px;
    }

    &--false {
      display: none;
    }

    &--true {
      display: block;
    }

    &--connect {
        $h: 320px;
        height: $h;
        top: calc(50vh - $h/2);
    }

    &__close {
      position: absolute;
      right: 16px;
      top: -0;
      border: none;
      font-weight: 400;
      text-transform: lowercase;
      background: none;
      padding: 0;
      &:hover {
        background: none;
        color: white;
      }
    }
    h2 {
      text-align: center;
      text-transform: uppercase;
      margin: 0;
    }
    &__inside {
      position: absolute;
      top: 36px;
      left: 16px;
      right: 16px;
      bottom: 16px;
      border: 2px solid white;
      border-radius: 0;
      padding: 16px;
      .buttons {
        position: absolute;
        bottom: 16px;
        right: 16px;

      }
      p {
        margin-top: 0;
      }
    }


  }

$landerGap: 32px;
  .lander {
    background: black;
    &--false {
      display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .lander-image{
      max-width: 100%;
      max-height: 100vh;
      text-align: center;
      img {
        max-width: 100%;
        max-height: 100vh;
      }
    }

    .lander-main {
      padding-top: $landerGap;
      padding-bottom: $landerGap + 8px;
      $w: 640px;
      width: $w;

      // lander height:
      // 55 + 45
      // landerGap * 3
      top: calc(50vh - 110px/2 - $landerGap * 3/2);
      left: calc(50vw - $w/2);
      position: fixed;
      background: rgba(0,0,0,0.95);
      border-radius: 10px;
      border: 2px solid rgba(255,255,255,0.2);
      color: white;
      text-align: center;
      &--true {
        display: none;
      }

    }

    .lander-social {
      $landerSocialSize: 64px;
      position: fixed;

      bottom: 0;
      right: 0;
      height: $landerSocialSize;

      .social-link {
        height: 100%;
        width: $landerSocialSize;
        display: inline-block;
        position: relative;
        $socialIconSize: $landerSocialSize/2;
        img {
          position: relative;
          height: $socialIconSize;
          width: $socialIconSize;
          top: ($landerSocialSize - $socialIconSize)/2;
          left: ($landerSocialSize - $socialIconSize)/2;
        }
      }
    }
    .lander-copyright {
      position: fixed;
      bottom: 16px;
      font-size: 16px;
      width: 100%;
      text-align: center;
      color: rgba(255,255,255,0.7);
      letter-spacing: 2px;

    }
  }

  .lander-msg {
    font-size: 32px;
    margin-bottom: $landerGap + 8px;
  }

  .lander-button {
    button {
      font-size: 32px;
      border-radius: 5px;
      border: 2px solid white;
      padding: 4px $landerGap;
    }

  }

  .sarpanch-regular {
  font-family: "Sarpanch", serif;
  font-weight: 400;
  font-style: normal;
}

.sarpanch-medium {
  font-family: "Sarpanch", serif;
  font-weight: 500;
  font-style: normal;
}

.sarpanch-semibold {
  font-family: "Sarpanch", serif;
  font-weight: 600;
  font-style: normal;
}

.sarpanch-bold {
  font-family: "Sarpanch", serif;
  font-weight: 700;
  font-style: normal;
}

.sarpanch-extrabold {
  font-family: "Sarpanch", serif;
  font-weight: 800;
  font-style: normal;
}

.sarpanch-black {
  font-family: "Sarpanch", serif;
  font-weight: 900;
  font-style: normal;
}

.courier-prime-regular {
  font-family: "Courier Prime", serif;
  font-weight: 400;
  font-style: normal;
}

.courier-prime-bold {
  font-family: "Courier Prime", serif;
  font-weight: 700;
  font-style: normal;
}

.courier-prime-regular-italic {
  font-family: "Courier Prime", serif;
  font-weight: 400;
  font-style: italic;
}

.courier-prime-bold-italic {
  font-family: "Courier Prime", serif;
  font-weight: 700;
  font-style: italic;
}

strong {
  font-family: "Courier Prime", serif;
  font-weight: 700;
}
.dialog__speaker--peephole, .dialog__speaker--gameover {
  text-align: center;
}
.dialog__speaker--peephole img {

  $rat: 1620/911;

  margin: 0 auto;

  max-width: 100vw;
}

.dialog__speaker--gameover img {
  margin: 10vh auto 0;
  height: auto;
  width: 50%;

}
.show-on-mobile {
  display: none;
}
// ---------------------------------------------------------------------------------------

.lander .lander-main.lander-main--mobile-true {
  display: none;
}

.modal__img {
  width: 100%;
  text-align: center;

  img {
    width: auto;
    height: 224px;
  }
}

a {
  color: white;
}
a:visited {
  color: white;
}
 


// ---------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------

@media (orientation: portrait) {
.lander .lander-image {
  height: 100vh;
  max-width: initial;
  img {
    height: 100vh;
    max-width: initial;
    position: relative;
    $rat: 2732/1565;

    left: calc(  50vw - 50vh * $rat  );


  }
}

.lander .lander-main {
  width: 94vw;
  left: 3vw;
  box-sizing: border-box;
  top: 128px;
}



.lander .lander-social {
  bottom: 48px;
  text-align: center;
  left: 0;
  right: 0;

}
.lander .lander-social .social-link img {
  left: 0;
}

.modal {
  width: 94vw;
  box-sizing: border-box;
  left: 3vw;
}

.modal--connect {
  height: 70vh;
  top: 15vh;
}
.modal--forge {
  height: 90vh;
}

.menu-container {
  width: 94vw;
  box-sizing: border-box;
  left: 3vw;
}


.dialog__text {
  left: 3vw;
  right: 3vw;
}
.dialog__options, .dialog__nav {
  left: 3vw;
  width: 93vw;

}
.header {
  bottom: 0;
  display: none;
}
/*
.dialog__speaker--full img {
    $h: 50vh;
    height: $h;
    top: (100vh - $h)*0.5;
    max-width: initial;
    position: relative;
    $rat: 2732/1565;

    left: calc(  50vw - $h * $rat * 0.5  );


  
}
*/

.dialog__speaker--full img {

    height: auto;
    top: 33vh;
    
    position: relative;

    width: 100%;
    left: 0;


  
}
.dialog__speaker--barkeep img {
    $h: 90vh;
    height: $h;
    top: (100vh - $h)*0.5;
    max-width: initial;
    position: relative;
    $rat: 2732/2048;

    left: calc(  50vw - $h * $rat * 0.5  );
    width: auto;

  
}
.dialog__speaker--fall2 img,
.dialog__speaker--falldot img,
.dialog__speaker--falldot2 img,
.dialog__speaker--falldot3 img {
    $h: 90vh;
    height: $h;
    top: (100vh - $h)*0.5;
    max-width: initial;
    position: relative;
    $rat: 2732/2048;

    left: calc(  50vw - $h * $rat * 0.5  );
    width: auto;

  
}

.dialog__speaker--emerge2 img {
  position: relative;
  top: 37vh;
}
.dialog__speaker--emergeSkip img {
  position: relative;
  top: 48vh;
}

.dialog__speaker--minesDistance img {
  width: 200vw;
  left: -50vw;
  top: 20vh;
}

.dialog__speaker--cottageOutside img {
  width: 200vw;
  left: -50vw;
  top: 33vh;
}
.dialog__speaker--lounge img {
  width: 180vw;
  left: -50vw;
  top: 15vh;
}
.dialog__speaker--entryway img {
  width: 200vw;
  left: -50vw;
  top: 15vh;
}
.dialog__speaker--stairs img {
  width: 200vw;
  left: -50vw;
  top: 15vh;
}
.dialog__speaker--sidewalk img {
  width: 180vw;
  left: -40vw;
  top: 15vh;
}
.dialog__speaker--mines img {

  width: 140vw;
  left: -20vw;
  top: 20vh;
}
.dialog__speaker--followStairs img {

  width: 140vw;
  left: -20vw;
  top: 28vh;
}
.dialog__options {
  bottom: 48px;
  position: fixed;
}
.dialog__nav {
  bottom: 0;
}

.dialog__options input {
  font-size: 24px;
}

.modal__img img {
  height: 128px;
}
.modal__inside {
  .buttons {
    width: 100%;
    text-align: center;
    bottom: 0;
    right: 0;
    button {
      display: block;
      margin: 8px auto;
    }
  }
}

.briefcase {
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
}

.inv {
  width: 100%;
  left: 0;
}
.inv--relics {
  left: 0;
  top: 50vh;
}

.briefcase__items {
  border: none;
}

.briefcase__item {
  width: 96px;
  height: 96px;

  img {
    height: 64px;
    width: 64px;
  }
}

.modal--forge {
  top: 3vw;
  height: 70vh;
}
.hide-on-mobile, .modal__inside .buttons button.hide-on-mobile {
  display: none;
}
.show-on-mobile {
  display: block;
}

//---------------------------------------------
}
//---------------------------------------------


.screen-showing--false {
  display: none;
}

// -------------------------------------------------------------
// -------------------------------------------------------------
// -------------------------------------------------------------
// --- Dashboard ----------------------------------------------
// -------------------------------------------------------------
// -------------------------------------------------------------
// -------------------------------------------------------------
$dashHeight: 100vh;
.dashboard {
  height: $dashHeight;
  position: relative;
  max-width: 720px;
  margin: 0 auto;
}

.dashboard__image {
  position: absolute;
  top: 0;
  height: $dashHeight/2;
  width: 100%;
  text-align: center;
  img {
    height: 100%;
  }
}

.dashboard__inventory {
  position: absolute;
  top: $dashHeight/2;
  //height: $dashHeight/2;
  width: 100%;
  padding-bottom: 64px;
}

$dashRowHeight: 200px;
$dashRowPadding: 16px;
$dashTitleHeight: 32px;
$dashItemHeight: $dashRowHeight - $dashRowPadding*3 - $dashTitleHeight;
.dashboard__row {
  box-sizing:border-box;
  border: 3px solid white;
  border-radius: 10px;
  height: $dashRowHeight;
  padding: $dashRowPadding;

}
.dashboard__row + .dashboard__row {
  margin-top: $dashRowPadding;
}

p.dashboard__rowtitle {
  color: white;
  height: $dashTitleHeight;
  margin: 0;
  margin-bottom: $dashRowPadding;
  font-size: 2*$dashTitleHeight/3;
  line-height: $dashTitleHeight;
}



.dashboard__item {
  cursor: pointer;
  position: relative;
  display: inline-block;

  height: $dashItemHeight;
  width: $dashItemHeight;
  box-sizing: border-box;
  border: 3px solid white;
  border-radius: 5px;
  img {
    height: 100%;
    width: 100%;
  }
  .dashboard__balance {
    position: absolute;
    bottom: 0;
    right: 2px;
    color: white;
    font-size: $dashItemHeight/6;
    font-weight: 700;
    &::before {
      content: "x";
    }
  }

  &:hover {
    filter: drop-shadow(0 0 4px rgba(255,255,255,1));
  }
}

.dashboard__item + .dashboard__item {
  margin-left: $dashRowPadding;
}

.status {
  color: white;
}


.modal-status--ready, .modal--Familiar-Dust.modal-statusFD--ready {
  .mining__btn.btn--rest {
    display: none;
  }
  .status-msg.status--resting {
    display: none;
  }
  .status-msg.status--mining {
    display: none;
  }
}
.modal-status--newUser, .modal-statusFD--newUser  {
  .mining__btn.btn--rest {
    display: none;
  }
  .status-msg.status--resting {
    display: none;
  }
  .status-msg.status--mining {
    display: none;
  }

  .mining__btn.btn--refresh {
    display: none;
  }
}
.modal-status--resting, .modal--Familiar-Dust.modal-statusFD--resting {
  .mining__btn.btn--mine {
    display: none;
  }
  .status-msg.status--ready {
    display: none;
  }
  .status-msg.status--mining {
    display: none;
  }
}
.modal-status--mining, .modal--Familiar-Dust.modal-statusFD--mining {
  .mining__btn.btn--mine {
    display: none;
  }
  .status-msg.status--ready {
    display: none;
  }
  .status-msg.status--resting {
    display: none;
  }
}

.btn--refresh {
  margin-left: 16px;
}

.fdf-bal {
  display: none;
}

.modal--Familiar-Dust {
  .fdf-bal {
    display: block;
  }
}

input {
  background: #111;
  border: 2px solid #111;
  padding: 6px 12px;
  border-radius: 6px;
  color: white;
  font-family: "Courier Prime", serif;
  font-size: 24px;
  width: 128px;
  outline: none;
  margin-right: 8px;
  line-height: 34px;
  &:focus {
    border: 2px solid #111;
    outline: none;
   
  }
}

.modal__img {
  width: 100%;
  text-align: center;

  img {
    width: auto;
    height: 224px;
  }
}



.screen--dashboard {
  background: black;
}

.screen-showing--true {
  display: block;
}
.screen-showing--false {
  display: none;
}

.screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  color: white;
  overflow-y: scroll;
  @include utils.scrolly;
  padding-bottom: 64px;
}


.close-screen {
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  text-align: center;
  width: 64px;
  height: 64px;
  div {
    line-height: 64px;
    font-size: 24px;
    font-weight: 700;
  }
  cursor: pointer;
}
$miningPadding: 16px;
.mining__name {
  font-size: 32px;
  margin-top: $miningPadding;
  margin-bottom: $miningPadding;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.mining__bal {
  font-size: 19px;
  margin-bottom: $miningPadding;
  font-family: "Courier Prime", serif;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
}

.mining__img {
  height: 128px;
  width: 100%;
  text-align: center;
  img {
    height: 100%;
  }
}

.mining__stats {
  text-align: left;
  font-size: 17px;
  max-width: 320px;
  margin: 0 auto $miningPadding;
  font-family: "Courier Prime", serif;
  letter-spacing: 1px;
}

$modalWidth: 420px;
$modalHeight: 500px;
.modal--Karma, .modal--Familiar-Dust, .modal--Sacred-Rock, .modal--forge {
    $w: $modalWidth;
    position: fixed;
    background: black;
    color: white;
    border: 2px solid white;
    padding: 0 16px 16px;
    width: $w;
    border-radius: 10px;
    left: calc(50vw - $w/2);
    $h: $modalHeight;
    height: $h;

    margin: 0;
    top: calc(50vh - $h/2);
    text-align: center;
    .close-modal {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  width: 64px;
  height: 64px;
  div {
    line-height: 64px;
    font-size: 24px;
    font-weight: 700;
  }
  cursor: pointer;
}
}

.modal-status--ready, .modal--Familiar-Dust.modal-statusFD--ready {
  .mining__btn.btn--rest {
    display: none;
  }
  .status-msg.status--resting {
    display: none;
  }
  .status-msg.status--mining {
    display: none;
  }
}
.modal-status--newUser, .modal-statusFD--newUser  {
  .mining__btn.btn--rest {
    display: none;
  }
  .status-msg.status--resting {
    display: none;
  }
  .status-msg.status--mining {
    display: none;
  }

  .mining__btn.btn--refresh {
    display: none;
  }
}
.modal-status--resting, .modal--Familiar-Dust.modal-statusFD--resting {
  .mining__btn.btn--mine {
    display: none;
  }
  .status-msg.status--ready {
    display: none;
  }
  .status-msg.status--mining {
    display: none;
  }
}
.modal-status--mining, .modal--Familiar-Dust.modal-statusFD--mining {
  .mining__btn.btn--mine {
    display: none;
  }
  .status-msg.status--ready {
    display: none;
  }
  .status-msg.status--resting {
    display: none;
  }
}


.fdf-bal {
  display: none;
}

.modal--Familiar-Dust {
  .fdf-bal {
    display: block;
  }
}


.Familiar-Crystal {
  input {
    display: none;
  }
  .btn--shrine {
    display: block;
    margin: 0 auto;
  }
  .btn--forge {
    display: none;
  }
  .max {
    display: none;
  }
  .active-shrine, .active-shrine-fee {
    display: block;
  }

  .btn--enter, .btn--leave {
    display: inline-block;
    margin: 0 4px;

  }
}

.active-shrine,.btn--shrine,.active-shrine-fee {
  display: none;
}


.btn--shrine {
  display: none;
}

.fambal--0 {
  .status--fam {
    display: block;
    font-weight: 700;
  }
  .status--ready {
    display: none;
  }
  .btn--mine {
    display: none;
  }
}

.status--fam {
  display: none;
}